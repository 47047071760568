import { Injectable } from '@angular/core';
import { SelectInputComponent } from './select-input.component';

@Injectable()
export class SelectService {
  private parentInstance!: SelectInputComponent;

  /**
   * Sets SelectInputComponent component to parentInstance property
   * @param {SelectInputComponent} parent - SelectInputComponent class
   * @memberOf SelectInputComponent
   */
  init(parent: SelectInputComponent) {
    this.parentInstance = parent;
  }

  /**
   * Returns parent SelectInputComponent component
   *
   * @memberOf SelectInputComponent
   */
  getParent(): SelectInputComponent {
    return this.parentInstance;
  }
}
