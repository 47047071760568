import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { Highlightable } from '@angular/cdk/a11y';
import { SelectService } from './../select-input/select-input.service';

@Component({
  selector: 'et-atoms-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOptionComponent implements Highlightable, AfterViewInit {
  @Input() value?: string | number | undefined | boolean;
  displayValue: string | undefined;

  @HostBinding('class.disabled')
  @Input()
  disabled = false;

  @ViewChild('content')
  innerContent!: ElementRef;

  selectService!: SelectService;

  @HostBinding('class.active') active = false;

  @HostBinding('class.hidden')
  private _hidden = false;

  get hidden(): boolean {
    return this._hidden;
  }

  @HostBinding('class.selected')
  public get selected(): boolean {
    return this.selectService?.getParent().selectedOption === this;
  }

  @HostListener('click', ['$event']) onSelect(event: UIEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.selectService.getParent().onSelect(this);
  }

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    const displayValue = this.innerContent.nativeElement.innerText;
    if (displayValue) {
      this.displayValue = displayValue;
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Sets focus on the element and active property to true
   *
   * @memberOf SelectOptionComponent
   */
  setActiveStyles(): void {
    this.focus();
    this.active = true;
  }

  /**
   * Sets active property to false
   *
   * @memberOf SelectOptionComponent
   */
  setInactiveStyles(): void {
    this.active = false;
  }

  /**
   * Returns value of the option
   *
   * @memberOf SelectOptionComponent
   */
  getLabel(): string {
    return this.value?.toString() as string;
  }

  /**
   * Should set focus state on native element
   * @param {FocusOptions} options - focus options
   * @memberOf SelectOptionComponent
   */
  focus(options?: FocusOptions) {
    this.elementRef.nativeElement.focus(options);
  }

  hide(): void {
    this._hidden = true;
  }

  show(): void {
    this._hidden = false;
  }

  scrollIntoView(options?: ScrollIntoViewOptions) {
    this.elementRef.nativeElement.scrollIntoView(options);
  }
}
