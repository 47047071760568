import { selectConnectedPosition } from './select-input.config';
import { CdkConnectedOverlay, ConnectedPosition } from '@angular/cdk/overlay';
import { ViewportRuler } from '@angular/cdk/scrolling';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import {
  DOWN_ARROW,
  ENTER,
  hasModifierKey,
  LEFT_ARROW,
  RIGHT_ARROW,
  TAB,
  UP_ARROW,
} from '@angular/cdk/keycodes';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SelectService } from './select-input.service';
import { SelectOptionComponent } from './../select-option/select-option.component';
import { selectReveal } from './select-input.animations';
import { EtSelectIconDirective } from './select-icon.directive';

interface SelectedOption {
  value: string | number | undefined | boolean;
}

interface OnSelectOpt {
  skipSelectionChangeEmit?: boolean;
}

@Component({
  selector: 'et-atoms-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  animations: [selectReveal],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectInputComponent,
      multi: true,
    },
    SelectService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectInputComponent
  implements OnInit, OnDestroy, AfterContentInit, ControlValueAccessor
{
  /**
   * Responsible for typeahead functionality
   * @default true
   */
  @HostBinding('class.searchable')
  @Input()
  searchable = true;

  @Input()
  searchableNotFoundMsg = 'Option not found';

  /**
   * Prevent internal search filtering
   * @default false
   */
  @Input() preventSearchableFilter = false;

  searchableCtrl = new FormControl<string>('');

  @Output() readonly selectionChange = new EventEmitter();
  @Output() readonly searchChanged = new EventEmitter<string>();

  triggerRect!: ClientRect;

  keyManager!: ActiveDescendantKeyManager<SelectOptionComponent>;

  onChange!: (value: string) => void;
  onTouched!: () => void;

  private _displayOption: SelectedOption | undefined;

  private _selectedOption!: SelectOptionComponent;

  private _value: string | undefined;

  @HostBinding('class.opened')
  private _panelOpen = false;

  private _focused = false;

  private _disabled = false;

  private readonly destroy$ = new Subject<void>();

  positions: ConnectedPosition[] = selectConnectedPosition;

  @HostBinding('attr.tabIndex') tabIndex = 0;

  @HostBinding('attr.role') role = 'combobox';

  @HostBinding('attr.aria-autocomplete') autocomplete = 'none';

  @ContentChildren(SelectOptionComponent, { descendants: true })
  private selectOptions!: QueryList<SelectOptionComponent>;

  @ContentChild(EtSelectIconDirective)
  icon!: ElementRef;

  @ViewChild('trigger')
  private trigger!: ElementRef;

  @ViewChild('searchableInput')
  private searchableInput!: ElementRef<HTMLInputElement>;

  @ViewChild(CdkConnectedOverlay, { static: true })
  private connectedOverlay!: CdkConnectedOverlay;

  @ViewChild('panel')
  private panel!: ElementRef;

  @ViewChild('notFoundMsgContainer', { read: ViewContainerRef })
  private notFoundMsgContainer!: ViewContainerRef;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.panelOpen
      ? this.handleOpenKeyDown(event)
      : this.handleClosedKeyDown(event);
  }

  @HostListener('focus') onFocus() {
    if (!this.disabled) {
      this._focused = true;
    }
  }

  @HostListener('blur') onBlur() {
    this._focused = false;
    if (!this.disabled && !this.panelOpen) {
      this.changeDetectorRef.markForCheck();
    }
  }

  get panelOpen(): boolean {
    return this._panelOpen;
  }

  get focused(): boolean {
    return this._focused;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  get displayValue(): string {
    return this._displayOption?.value?.toString() || '';
  }

  get selectedOption(): SelectOptionComponent {
    return this._selectedOption;
  }

  get empty(): boolean {
    return !this._selectedOption;
  }

  get windowWidth() {
    return this.triggerRect?.width as string | number;
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private viewportRuler: ViewportRuler,
    private selectService: SelectService,
  ) {}

  ngOnInit(): void {
    this.viewportRuler
      .change()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.panelOpen) {
          this.triggerRect = this.trigger.nativeElement.getBoundingClientRect();
          this.changeDetectorRef.markForCheck();
        }
      });

    this.searchableCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => this.handleSearchInput(value));
  }

  ngAfterContentInit() {
    this.initKeyManager();
    this.selectOption({ skipSelectionChangeEmit: true });
    this.selectOptions.forEach((optionComponent) => {
      optionComponent.selectService = this.selectService;
      optionComponent.selectService.init(this);
    });
    // Update service when options changes
    this.selectOptions.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.selectOptions.forEach((optionComponent) => {
        optionComponent.selectService = this.selectService;
        optionComponent.selectService.init(this);
      });
      // Select an option after passing new options
      this.selectOption();
    });
  }

  /**
   * Update select option with value
   * @param {string} value - value to select
   *
   * @memberOf SelectInputComponent
   */
  writeValue(value: string): void {
    this._value = value;
    this.setOption();
  }

  /**
   * Set option value without triggering value change
   *
   * @memberOf SelectInputComponent
   */
  setOption() {
    const selectedOption = this.selectOptions?.find(
      (o) => o.value === this._value,
    );
    if (this.selectOptions && selectedOption) {
      this._displayOption = {
        value: selectedOption.displayValue || selectedOption.value,
      };
      this._selectedOption = selectedOption;
      this.renderer.addClass(this.elementRef.nativeElement, 'selected');
      this.keyManager.setActiveItem(selectedOption);
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'selected');
      this._displayOption = undefined;
    }
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Select option from private value property
   *
   * @memberOf SelectInputComponent
   */
  selectOption(opt?: OnSelectOpt) {
    const selectedOption = this.selectOptions?.find(
      (o) => o.value === this._value,
    );
    if (this.selectOptions && selectedOption) {
      this.onSelect(selectedOption, opt);
    }
  }

  /**
   * Value Accesser register onChange function
   * @param {function} fn - function to call whten calling onChange method
   * @memberOf SelectInputComponent
   */
  registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  /**
   * Value Accesser register OnTouched function
   * @param {function} fn - function to call whten calling OnTouched method
   * @memberOf SelectInputComponent
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Value Accesser handling disabled state
   * @param {boolean} isDisabled - boolean
   * @memberOf SelectInputComponent
   */
  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  /**
   * Handle scroll position and selected item when opening select menu
   *
   * @memberOf SelectInputComponent
   */
  onAttach() {
    this.connectedOverlay.positionChange.pipe(take(1)).subscribe(() => {
      this.changeDetectorRef.detectChanges();
      this.highlightOption();

      if (this.panelOpen && this.panel) {
        this.scrollPosition(this.keyManager.activeItemIndex || 0);
      }
    });
    this.updateRect();
  }

  /**
   * Selects an option
   * @param {SelectOptionComponent} option - SelectOptionComponent
   * @memberOf SelectInputComponent
   */
  onSelect(option: SelectOptionComponent, opt?: OnSelectOpt) {
    this.keyManager.setActiveItem(option);
    this._selectedOption = option;
    this._displayOption = { value: option.displayValue || option.value };
    this.close();
    this.focus();
    this.changeDetectorRef.markForCheck();
    if (!opt?.skipSelectionChangeEmit) {
      this.onChange && this.onChange(option.value as string);
      this.selectionChange.emit(option.value);
    }
    if (option.value || option.value === 0) {
      this.renderer.addClass(this.elementRef.nativeElement, 'selected');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'selected');
    }
  }

  /**
   * Opens select menu
   *
   * @memberOf SelectInputComponent
   */
  open() {
    if (this._disabled) {
      return;
    }
    if (this.onTouched) {
      this.onTouched();
    }
    this.updateRect();
    this.highlightOption();
    this.keyManager.withHorizontalOrientation(null);
    this._panelOpen = !this._panelOpen;
    if (this.searchable) {
      setTimeout(() => {
        this.searchableInput?.nativeElement.focus();
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  /**
   * Close select menu
   */
  close() {
    if (this.panelOpen) {
      this._panelOpen = false;
      this.searchableCtrl.reset();
      this.changeDetectorRef.markForCheck();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * This function is called when the user types something in the search input
   * 1. It will emit the searchChanged event
   * 2. It will filter the options based on the input if preventSearchableFilter
   * is false
   * @param {string} search search string
   */
  private handleSearchInput(search: string | null) {
    this.searchChanged.emit(search?.toString());

    if (this.preventSearchableFilter) return;

    this.notFoundMsgContainer?.clear();

    this.selectOptions.forEach((option) => {
      const displayValue = option.displayValue?.toString().toLowerCase();
      const fieldValue = option.value?.toString().toLowerCase();
      const searchValue = displayValue || fieldValue;
      if (
        search &&
        searchValue &&
        searchValue?.indexOf(search?.toLowerCase()) > -1
      ) {
        option.show();
      } else if (!search) {
        option.show();
      } else {
        option.hide();
      }
    });
    const hasOptions = this.selectOptions.some((option) => !option.hidden);
    if (hasOptions) {
      this.keyManager.setFirstItemActive();
      this.scrollPosition(this.keyManager.activeItemIndex || 0);
    } else {
      this.showNoOptionsMsg();
    }
    this.changeDetectorRef.markForCheck();
  }

  private showNoOptionsMsg() {
    this.notFoundMsgContainer.clear();
    const componentRef = this.notFoundMsgContainer.createComponent(
      SelectOptionComponent,
    );
    componentRef.setInput('value', this.searchableNotFoundMsg);
    componentRef.setInput('disabled', true);
  }

  /**
   * Init key manager to select with keys
   *
   * @memberOf SelectInputComponent
   */
  private initKeyManager() {
    this.keyManager = new ActiveDescendantKeyManager<SelectOptionComponent>(
      this.selectOptions,
    )
      .withTypeAhead()
      .withVerticalOrientation()
      .withHorizontalOrientation('ltr')
      .withHomeAndEnd()
      .withAllowedModifierKeys(['shiftKey'])
      .withWrap()
      .skipPredicate((option) => option.hidden);

    this.keyManager.tabOut.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.focus();
      this.close();
    });
  }

  /**
   * Navigate select menu with keys
   *
   * @memberOf SelectInputComponent
   */
  private handleClosedKeyDown(event: KeyboardEvent) {
    const keyCode = event.keyCode;
    const isArrowKey =
      keyCode === DOWN_ARROW ||
      keyCode === UP_ARROW ||
      keyCode === LEFT_ARROW ||
      keyCode === RIGHT_ARROW;
    const isOpenKey = keyCode === ENTER;
    const manager = this.keyManager;

    if (
      (!manager.isTyping() && isOpenKey && !hasModifierKey(event)) ||
      (event.altKey && isArrowKey)
    ) {
      event.preventDefault();
      this._panelOpen = true;
      setTimeout(() => {
        this.searchableInput?.nativeElement.focus();
        this.changeDetectorRef.markForCheck();
      });
    } else {
      manager.onKeydown(event);
    }
  }

  /**
   * Open / close menu with keys
   *
   * @memberOf SelectInputComponent
   */
  private handleOpenKeyDown(event: KeyboardEvent) {
    const manager = this.keyManager;
    const keyCode = event.keyCode;
    const isArrowKey = keyCode === DOWN_ARROW || keyCode === UP_ARROW;
    const isTyping = manager.isTyping();
    const isTab = keyCode === TAB;

    if (isArrowKey && event.altKey) {
      // Close the select on ALT + arrow key to match the native <select>
      event.preventDefault();
      this.close();
    } else if (
      !isTyping &&
      keyCode === ENTER &&
      manager.activeItem &&
      !hasModifierKey(event)
    ) {
      event.preventDefault();
      this.onSelect(manager.activeItem);
    } else if (isArrowKey && this.panelOpen && this.panel) {
      manager.onKeydown(event);
      this.scrollPosition(this.keyManager.activeItemIndex || 0);
    } else if (isTab) {
      this.focus();
      this.close();
    }

    this.changeDetectorRef.markForCheck();
  }

  /**
   * Fires focus event on select component
   * @param {FocusOptions} options - focus options
   * @memberOf SelectInputComponent
   */
  private focus(options?: FocusOptions) {
    this.elementRef.nativeElement.focus(options);
  }

  /**
   * Highlights menu depending on selected option
   *
   * @memberOf SelectInputComponent
   */
  private highlightOption(): void {
    if (this.keyManager) {
      if (this.empty) {
        this.keyManager.setFirstItemActive();
      } else {
        this.keyManager.setActiveItem(this._selectedOption);
      }
    }
  }

  /**
   * Scrolls to selected option on long option list
   * @param {Number} index - focus options
   * @memberOf SelectInputComponent
   */
  private scrollPosition(index: number) {
    if (index === 0 && this.panel) {
      this.panel.nativeElement.scrollTop = 0;
    } else {
      this.selectOptions.get(index)?.scrollIntoView({ block: 'center' });
    }
  }

  /**
   * Updates select field coordinates
   *
   * @memberOf SelectInputComponent
   */
  private updateRect() {
    this.triggerRect = this.trigger.nativeElement.getBoundingClientRect();
  }
}
