import { ConnectedPosition } from '@angular/cdk/overlay';

export const SELECT_PANEL_HEIGHT = 256;

export const SELECT_PANEL_PADDING = 5;

export const SELECT_OPTION_HEIGHT = 39;

export const selectConnectedPosition: ConnectedPosition[] = [
  {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    offsetY: 1,
  },
  {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
    offsetY: -1,
  },
];
