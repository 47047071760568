import {
  DocprepMarkupMetadata,
  DocprepMarkupPageTabGroup,
  ElsiTabType,
} from '@et/typings';
import {
  Dimensions,
  MarkupTab,
  MarkupTemplateProperties,
} from '@escrowtab/docprep-api-client';
import { AuditAction } from '@escrowtab/packets-api-client';

export interface ElsiPacketResponse {
  signerId: string;
  packet: ElsiPacket;
  markUp: ElsiMarkup;
  files: ElsiFile[];
}

export interface ElsiFile {
  fileName: string;
  fileUri: string;
}

export interface ElsiPacket {
  packetId: string;
  packetName: string;
  packetStatus: string;
  originatorId: string;
  lenderId?: string;
  titleAgentId: string;
  titleAgencyId?: string;
  closingAgentId?: string;
  signingAgentId?: string;
  closingDate: Date;
  isEstimatedDate: boolean;
  closingType: string;
  packetState: string;
  hasFinalClosingDisclosure: boolean;
  isTestPacket: boolean;
  isAutoPrepRequired: boolean;
  isExternal: boolean;
  isAuthoritativeCopy: boolean;
  isEnoteAuthoritativeCopy: boolean;
  isArchived: boolean;
  notificationEmail?: string;
  packetSource: string;
  deliverySource: string;
  eNoteId?: string;
  eNoteType?: string;
  mersIdentificationNumber?: string;
  signers: ElsiPacketSigner[];
  utcDateCreated: Date;
  utcDateModified: Date;
  buyerOrSeller: number;
  totalPages?: number;
}

export interface ElsiPacketSigner {
  signerId: string;
  packetId: string;
  signerName: string;
  signerEmail: string;
  signerDescription: string;
  isBuyer: boolean;
  utcDateCreated: Date;
  utcDateModified: Date;
  viewOnly: boolean;
}

export interface ElsiMarkup {
  id: string;
  externalId: string;
  type: string;
  name?: string;
  isTestDocument: boolean;
  status: string;
  numberOfPages: number;
  pages: ElsiMarkupPage[];
  metadata: DocprepMarkupMetadata;
}

export interface ElsiMarkupPage {
  pageNumber: number;
  status: string;
  markup: ElsiMarkupPageMarkup;
}

export interface ElsiMarkupPageMarkup {
  template?: MarkupTemplateProperties;
  signature?: string | null;
  pageType?: string | null;
  tags?: any;
  dimensions?: Dimensions;
  tabs?: Array<ElsiMarkupTab>;
  tabGroups?: Array<DocprepMarkupPageTabGroup>;
}

export interface ElsiMarkupTab extends MarkupTab {
  completed: boolean;
  time?: string;
  text?: string;
  signatureId?: string;
}

export interface ElsiSignature {
  type: ElsiTabType;
  signatureId: string;
  signatureImage: string;
  wDimension: number;
  hDimension: number;
}

export interface ElsiDto {
  packetId: string;
  signerId: string;
  signatureImages: ElsiDtoSignatureImage[];
  signatureItems: ElsiDtoSignatureItem[];
  auditLogs: AuditAction[];
}

export interface ElsiDtoSignatureImage {
  signatureId: string;
  signatureImage: string;
  hDimension?: number;
  wDimension?: number;
}

export interface ElsiDtoSignatureItem {
  pageNumber: number;
  tabNumber?: number;
  type: string;
  time: string;
  signatureId: string;
  xCoordinate: number;
  yCoordinate: number;
  hDimension: number;
  wDimension: number;
}
