/**
 * Elsi signing canvas width
 */
export const SIGNING_PAD_WIDTH = 800;

/**
 * Elsi signing canvas height
 */
export const SIGNING_PAD_HEIGHT = 300;

/**
 * Value to scale signatures from original size
 * Increase this value to increase signature size
 * Decrease this value to decrease signature size
 */
export const SIGNATURES_SCALE = 0.5;

/**
 * Default page width in px.
 * Used for calculating signatures scale
 */
export const PAGE_WIDTH = 1224;

/**
 * Default page width in px when the page is loaded on large screen.
 */
export const PAGE_WIDTH_INITIAL = 700;

/**
 * Minimum page width in px
 */
export const MIN_PAGE_ZOOM = 500;

/**
 * Maximum page width in px
 */
export const MAX_PAGE_ZOOM = 2500;

/**
 * Tab types for Elsi signing
 */
export enum ElsiTabType {
  SIGNATURE = 'Signature',
  INITIALS = 'Initial',
  DATE = 'DateSigned',
  FREEFORM = 'Freeform',
  SELECTION = 'Selection',
}

/**
 * Autotag and elsi default tab values.
 * Default height is used to checkif tab is input or textarea
 */
export const DEFAULT_TAB_HEIGHT = 0.027;
export const DEFAULT_TAB_WIDTH = 0.2;
export const DEFAULT_SELECTION_TAB = 0.025;

/**
 * Maximum signature height in px
 * Signatures with height greater than this value will be scaled
 */
export const MAX_SIGNATURE_HEIGHT = 45;
