import { DocumentMarkup } from '@et/typings';

/**
 * It checks if the page has a signature complete
 * @param documentMarkup - DocumentMarkup
 * @param pageNumber - page number
 * @returns boolean
 */
export function isSignatureComplete(
  documentMarkup: DocumentMarkup,
  pageNumber: number,
) {
  // check if the page has tabs required
  const pageHasTabsRequired = documentMarkup.data.pages
    .filter((page) => page.page === pageNumber)[0]
    .tabs.some((tab) => tab.required === true);
  if (!pageHasTabsRequired) {
    return false;
  }
  return documentMarkup.data.pages
    .filter((page) => page.page === pageNumber)
    .every((page) =>
      page.tabs.every((tab) => tab.required === true && tab.signed === true),
    );
}

/**
 * It checks if all signatures are complete by type
 * @param documentMarkup - DocumentMarkup
 * @param type - type
 * @returns boolean
 */
export function isAllSignatureCompleteByType(
  documentMarkup: DocumentMarkup,
  type: string,
) {
  const filterByType = documentMarkup.data.pages.filter(
    (page) => page.type?.toLowerCase() === type?.toLowerCase(),
  );
  const result = filterByType.every((page) => {
    return page.tabs
      .filter((tab) => tab.required === true)
      .every((tab) => tab.signed === true);
  });
  return result;
}
/**
 * It checks if the page has a stamp required
 * @param documentMarkup - DocumentMarkup
 * @param pageNumber - page number
 * @returns boolean
 */
export function hasStampRequired(
  documentMarkup: DocumentMarkup,
  pageNumber: number,
) {
  return (
    documentMarkup.data.pages.filter((page) => page.page === pageNumber)[0]
      .notarize === true &&
    documentMarkup.data.pages.filter((page) => page.page === pageNumber)[0]
      .stamped === false
  );
}
/**
 * It checks if the page has a stamp complete
 * @param documentMarkup - DocumentMarkup
 * @param pageNumber - page number
 * @returns boolean
 */
export function isStampComplete(
  documentMarkup: DocumentMarkup,
  pageNumber: number,
) {
  return (
    documentMarkup.data.pages.filter((page) => page.page === pageNumber)[0]
      .stamped === true
  );
}

/**
 * It checks if the page is complete
 * @param documentMarkup - DocumentMarkup
 * @param pageNumber - page number
 * @returns boolean
 */
export function pageIsComplete(
  documentMarkup: DocumentMarkup,
  pageNumber: number,
) {
  const pageHasTabsRequired = documentMarkup.data.pages
    .filter((page) => page.page === pageNumber)[0]
    .tabs.some((tab) => tab.required === true);
  if (!pageHasTabsRequired) {
    return false;
  }
  return (
    !hasSignatureRequired(documentMarkup, pageNumber) &&
    !hasStampRequired(documentMarkup, pageNumber)
  );
}

/**
 * It checks if the page has a signature required
 * @param documentMarkup - DocumentMarkup
 * @param pageNumber - page number
 * @returns boolean
 */
export function hasSignatureRequired(
  documentMarkup: DocumentMarkup,
  pageNumber: number,
) {
  if (
    documentMarkup.data.pages.filter((page) => page.page === pageNumber)[0].tabs
      .length === 0
  ) {
    return false;
  }
  return documentMarkup.data.pages
    .filter((page) => page.page === pageNumber)
    .every((page) =>
      page.tabs.every((tab) => tab.required === true && tab.signed === false),
    );
}
